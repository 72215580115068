<template>
  <div class="container">
    <div class="logo">
        <img src="@/assets/chat.png">
        <div class="line"></div>
        <span>茄特</span>    
    </div>
    <div class="nav">
        <span @click="router.push('/')">
            首页
        </span>
        <span>
            企业服务
        </span>
        <span>
            私有化部署
        </span>
        <span>
            下载
        </span>
    </div>
    <div class="avator">
        <img :src="!store.introInfo.avatar?require('@/assets/avatar.webp'):store.introInfo.avatar" alt="">
        <div class="online" :style="{'background-color':store.introInfo.id?'greenyellow':'red'}"></div>
          <el-select v-model="value" placeholder="我的">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
    </div>
  </div>
  
</template>

<script setup>
import { ref,defineProps } from "vue";
import {useStore} from "@/pinia/index"
import {useRouter} from 'vue-router'
const router = useRouter()
const store = useStore()
const options = ref( [{
          value: '选项1',
          label: '我的主页'
        }, {
          value: '选项2',
          label: '我的私信'
        }])


</script>

<style lang="scss" scoped>
.container{
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 70px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-around;
    .logo{
        line-height: 60px;
        height: 60px;
        width: 120px;
        display: flex;
        justify-content: center;
        margin-left: -150px;
        img{
            vertical-align: top;
            height: 60px;
            width: 60px;
            margin-right: 10px;
        }
        .line{
            width: 0;
            height: 60px;
            border: 1px solid black;
            margin-right: 10px;
        }
    }
    .nav{
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span{
            cursor: pointer;
        }
    }
    .avator{
        position: relative;
        width: 200px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .online{
            position: absolute;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            left: 40px;
            top: 40px;
        }
        img{
            width: 55px;
            height: 55px;
            border-radius: 50%;
            border: 1px gainsboro solid;
        }
        .el-select{
            width: 100px;
        }
    }
}

</style>