<template>
  <div class="bg">
    <el-card class="login-card" shadow="hover">
      <span class="iconfont icon-wangwang"></span><br/>
      <!-- 登录表 -->
        <el-form
    ref="loginFormRef"
    :rules="loginRules"
    :model="logininfo"
    v-if="currPage"
  >

    <el-form-item prop="username">
          <el-input
          v-model="logininfo.username"
      style="width: 300px;height:45px"
      size="large"
      placeholder="用户名"
      prefix-icon="User"
    />
    </el-form-item>

    <el-form-item prop="password">
        <el-input
        v-model="logininfo.password"
      style="width: 300px;height:45px"
      size="large"
      placeholder="密码"
      prefix-icon="Lock"
    />
    </el-form-item>


    <el-form-item prop="captcha"> 
    <div class="codesection">
        <el-input
        v-model="logininfo.captcha"
        class="codeipt"
      style="width: 180px;height:45px"
      size="large"
      placeholder="验证码"
    />
      
    
    <div class="codeimg" @click="getCaptchaInfo"><img :src="captchaImg" alt=""></div>
    </div>
    </el-form-item>
    </el-form>

  <!-- 注册表 -->
    <el-form
    ref="registyFormRef"
    :rules="registyRules"
    :model="registyInfo"
    v-else
    class="regForm"
  >
    <el-form-item prop="username"><el-input
        v-model="registyInfo.username"
      style="width: 300px;height:45px"
      size="large"
      placeholder="用户名"
      prefix-icon="User"
    /></el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="registyInfo.password"
      style="width: 300px;height:45px"
      size="large"
      placeholder="密码"
      prefix-icon="Lock"
    />
    </el-form-item>
    <el-form-item prop="verifypassword">
      <el-input
        v-model="registyInfo.verifypassword"
      style="width: 300px;height:45px"
      size="large"
      placeholder="确认密码"
      prefix-icon="Lock"
    />
    </el-form-item>
    <el-form-item prop="phone">
      <el-input
        v-model="registyInfo.phone"
      style="width: 300px;height:45px"
      size="large"
      placeholder="手机号"
      prefix-icon="Phone"
    />
    </el-form-item>
    <div></div>
        <el-form-item prop="verty">
          <div>
            <el-input
              v-model="registyInfo.verty"
            style="width: 180px;height:45px"
            size="large"
            placeholder="验证码"
            prefix-icon="Message"
          />
          <el-button size="large" class="getVerty"  @click="openTip">获取验证码</el-button>
        </div>
      </el-form-item>
    </el-form>


    <br/>
    <el-button class="loginbtn" type="primary" round @click="submit">{{currPage?'登录':'注册'}}</el-button>
    <div class="otheroption">
      <el-link><el-text tag="b" @click="changePage">{{currPage?'创建新账号':'去登录'}}</el-text></el-link>
      <el-link><el-text tag="b" @click="ElMessage.error('该功能尚未完善')">忘记密码</el-text></el-link>
    </div>


    </el-card>
  </div>
</template>

<script setup>
import {useStore} from '@/pinia/index'
import { ref, onMounted, getCurrentInstance, reactive} from "vue";
import {ElMessage,ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router'

const currPage = ref(true)  //是否为登录页
const store = useStore()
const LOGIN_TOKEN = "LOGIN_TOKEN"
const router = useRouter()
const {proxy} = getCurrentInstance()
let captchaImg = ref('')
const loginFormRef = ref('');
const registyFormRef = ref('');
const logininfo = ref({
  'username':'',
  'password':'',
  'randomString':'',
  'captcha':''
})
const registyInfo = ref({
  'username':'',
  'password':'',
  'verifypassword':'',
  'phone':'',
  'verty':''
})
const registyRules = ref({
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 8, max: 12, message: '请输入8到12位的用户名', trigger: 'blur' },
  ],
    password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 8, max: 12, message: '请输入8到12位的密码', trigger: 'blur' },
  ],
    verifypassword: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { min: 8, max: 12, message: '请输入8到12位的密码', trigger: 'blur' },
  ],
  phone: [
    { required: true, message: '请输入邮箱', trigger: 'blur' },
    { min: 11, max: 19, message: '请输入11到19位的手机号', trigger: 'blur' },
  ],
  verty: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { min: 3, max: 6, message: '请输入3到6位的验证码', trigger: 'blur' },
  ]
})
const loginRules = ref({
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 8, max: 12, message: '请输入8到12位的用户名', trigger: 'blur' },
  ],
    password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 8, max: 12, message: '请输入8到12位的密码', trigger: 'blur' },
  ],
    captcha: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { min: 4, max: 4, message: '请输入完整4位验证码', trigger: 'blur' },
  ],
})

onMounted(() => {
  currPage.value?router.push('/login'):router.push('/registy')
  getCaptchaInfo()
})
const openTip = () => {
  ElMessageBox.alert('添加QQ2801562790获取验证码', '验证码', {
    confirmButtonText: 'OK'
  })
}
//请求验证码
const getCaptchaInfo = async ()=>{
  if(!currPage.value) return //注册页面返回
    const res = await proxy.$api.ApigetCaptchaInfo()
    console.log(res);
    logininfo.value.randomString = res.uuid
    captchaImg.value = res.base64
}
const submit = async ()=>{
  if(!currPage.value){ //注册
      registyFormRef.value.validate((vali)=>{
        if(!vali){
          return ElMessage.error('请完成信息输入')
        }else{
          if(registyInfo.value.password!=registyInfo.value.verifypassword){
            return ElMessage.error('密码不一致')
          }else if(!/^1[0-9]{10}$/.test(registyInfo.value.phone)){
            return ElMessage.error('请输入正确的手机号！')
         }else{
           registy(registyInfo.value)
         }
        }
      })
  }else{//登陆
      loginFormRef.value.validate( (valid)=>{
    if(!valid) {
      return ElMessage.error('请完成信息输入')
    }else{
      login(logininfo.value)
    }
  })
  }

}
const registy = async (registyInfo)=>{
  try {
  const res = await proxy.$api.gotoRegistyApi(registyInfo)
  ElMessage.success(res)
  } catch (error) {
  }  
}
const login = async (logininfo)=>{
  try {
    const res =  await proxy.$api.ApiGotoLogin(logininfo)
    ElMessage.success("登陆成功")
      sessionStorage.setItem(LOGIN_TOKEN,res)
      router.push('/')
  } catch (error) {
      getCaptchaInfo()
  }
}
const changePage =()=>{
    currPage.value = !currPage.value
    currPage.value?router.push('/login'):router.push('/registy')
}

</script>

<style lang="scss" scoped>
  .bg{
    width: 100%;
    height: 100vh;
    background-image: url('@/assets/loginbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    .login-card{
      .el-form-item{
        margin: 0 auto 40px;
        width: 300px;
      }
      .regForm{
        .el-form-item{
            margin: 0 auto 15px;
            width: 300px;
            .getVerty{
              margin-left: 10px;
            }
      }
      }
      text-align: center;
      border: 0;
      text-align: center;
      position: absolute;
      width: 480px;
      height: 600px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: rgba($color: #ffffff, $alpha: 0.2);
      border-radius: 20px;
      span{
        display: block;
        margin: 20px 0 20px 0;
        font-weight: 700;
        font-size: 80px;
        color: black;
        opacity: 1;
      }
      :deep(.el-input__wrapper){
        border-radius: 20px;
        opacity: 0.6;
      }
      :deep(.el-input__prefix){
          color: #000000;
      }
      :deep(.el-input__inner){
          color: #000000;
      }
      .el-input{
        font-weight: 500;
        font-size: 18px;
      }
      .codesection{
        display: flex;
        justify-content: space-between;
        width: 300px;
        margin: 0 auto;
        .codeimg{
          cursor: pointer;
          width: 100px;
          height: 45px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .loginbtn{
        margin-top: -20px;
        width: 300px;
        height: 45px;
        font-size: 20px;
        font-weight: 500;
      }
      .otheroption{
        margin: 0 auto;
        width: 300px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 15px;
        .el-text{
          color: #000000;
        }
      }
    }
  }
</style>