<template>

    <Header/>


    <div class="bg"><img src="@/assets/bg.jpg" alt="">
    <!-- 搜索 -->
    <div class="searchArea">
    <el-input
        style="width: 240px"
        placeholder="请输入感兴趣的内容"
        prefix-icon="Search"
    />
    <el-button type="danger" round @click="ElMessage.error('功能开发中')" >搜索</el-button>
    </div>
    </div>


    <div class="content">
        <Intro  class="left-card" :introInfo="introInfo">
            <template v-slot:btn>
                <el-button class="goPagebtn" v-if="token" @click="router.push(`/personpg/${store.introInfo.id}`)">查看主页</el-button>
                <el-button class="tologinbtn" type="primary" round size="large" v-else @click="router.push('/login')">立即登录</el-button>
            </template>
            <template v-slot:interact>
                <div class="interact" v-if="token">
                    <div @click="router.push(`/personpg/${store.introInfo.id}/focus`)"><span class="interact-name">关注</span><span class="iconfont icon-wodeguanzhu"></span><span class="interact-num">{{introInfo.focusCount}}</span></div>
                    <div @click="router.push(`/personpg/${store.introInfo.id}/fans`)"><span class="interact-name">粉丝</span><span class="iconfont icon-fensipa"></span><span class="interact-num">{{introInfo.fansCount}}</span></div>
                </div>
                <div class="logininfo" v-else>
                <el-text type="info" size="large" tag="b">还没有<el-text type="info" size="large" tag="ins">茄特</el-text>?</el-text>
                &nbsp;
                <el-text type="warning" size="large" tag="b">立即注册!</el-text>
            </div>
            </template>
        </Intro>
        <div class="main-content">
            <el-card  class="middle-card classify">
                <el-menu
                    mode="horizontal"
                    style="max-width: 1400px"
                    ellipsis
                    @click="ElMessage.error('功能开发中')"
                >
                    <el-menu-item index="1"><el-text tag="b">最新发布</el-text></el-menu-item>
                    <el-menu-item index="2"><el-text tag="b">最多喜欢</el-text></el-menu-item>
                    <el-menu-item index="3"><el-text tag="b">最多评论</el-text></el-menu-item>
                    <el-menu-item index="4"><el-text tag="b">最多转发</el-text></el-menu-item>
                </el-menu>
                <el-button type="danger" class="publish" @click="ElMessage.error('功能开发中')">发布+</el-button>
            </el-card>

            <ul  class="middle-card" v-infinite-scroll="getBlogInfo" infinite-scroll-immediate :infinite-scroll-disabled="loadingMoreDisabled" infinite-scroll-delay="800"  style="overflow: hidden">
                <li v-for="item in blogInfo" :key="item.id">
                    <Blog class="work-card" :blogInfoItem=item></Blog>
                </li>
                <div class="disloading" v-show="loadingMoreDisabled">暂时无更多内容~</div>
            </ul>
        </div>

        <el-card  class="right-card">
            <h2>公告栏</h2>
            <el-timeline style="max-width: 600px">
                <el-timeline-item
                v-for="item in notice"
                :key="item.id"
                :timestamp="item.createTime"
                >
                {{item.content}}
                </el-timeline-item>
            </el-timeline>
        </el-card>
    </div>

</template>
<script setup>
import {useStore} from '@/pinia/index.js'
import {onMounted, ref, getCurrentInstance} from 'vue'
import {useRouter} from 'vue-router'
import Header from "../components/Header"
import Intro from "../components/Intro.vue"
import Blog from "../components/Blog.vue"
import { ElMessage } from 'element-plus'
import {timeDifference,splitData} from '@/util/func'
import axios from 'axios'
const token = ref(sessionStorage.getItem('LOGIN_TOKEN')!=null)
const router = useRouter()
const loadingMoreDisabled = ref(false)
const notice = ref([])
const introInfo = ref({
    avatar:'',
    id:"",
    nickname:'未登录66',
    introduce:'请先登录',
    focusCount:'xxx',
    fanCount:'xxx',
})
const store = useStore()
const {proxy} = getCurrentInstance()
const blogInfo = ref([])
const blogSearch = ref({
    userId:'',
    type:1,
    pageNum:1,
    pageSize:3
})
onMounted(async () => {
    getBlogInfo(blogSearch.value)
    //未登录
    getMyInfo()
    getNotice()
})
const getBlogInfo = async(params)=>{
    const res = await proxy.$api.ApiGetBlogInfo(params)
    if(!res || res == null || res.length < 4){
        loadingMoreDisabled.value = true
    }
    res.forEach(item=>{
        item.tags = splitData(item.tags)
        item.media = splitData(item.media)
        item.publishTime = timeDifference(item.publishTime)
    })
    blogInfo.value.push(...res)
}
const getMyInfo = async ()=>{
    try {
        const res = await proxy.$api.ApiGetUserInfo(-1)
        store.introInfo = res
        introInfo.value = res
    } catch (error) {
    }
}
const getNotice = async ()=>{
    const res = await proxy.$api.ApiGetNotice()
    notice.value.push(...res)
}
</script>



<style lang="scss" scoped>
.bg{
    width: 100%;
    height: 248px;
    img{
        width: 100%;
        height: 100%;
    }
}
.searchArea{
    width: 340px;
    display: flex;
    justify-content: space-around;
    position: absolute; 
    top: 151px;
    left: 50%;
    transform: translate(-50%, 0%);
    :deep(.el-input__wrapper){
        border-radius: 50px;
    }
}
.content{
    display: flex;
    width: 100%;
    .el-card{
        border-radius: 4px;
        margin: 15px 0;
    }

    .left-card{
        position: fixed;
        margin-left: 50px;
        .goPagebtn{
            margin: 20px 0;
            width: 200px;
            border-radius: 17px;
            border: 3px solid rgb(154, 204, 251);
        }
        .tologinbtn{
            margin: 20px 0;
            width: 200px;
            border-radius: 17px;
        }
        .interact{
            cursor: pointer;
            align-content: space-around;
            display: flex;
            justify-content: space-around;
            div{
                padding: 0 20px;
            }
            .iconfont{
                font-size: 30px;
            }
            .interact-name{
                font-size: 20px;
            }
            .interact-num{
                color: red;
                font-size: 20px;
                font-weight: 600;
            }
            span{
                margin: 10px 0;
                display: block;
            }
            }
    }
    .main-content{
        .disloading{
            width: 100%;
            height: 50px;
            font-size: 25px;
            font-weight: 500;
            text-align: center;
            margin: -15px 0 0 10px;
        }
        margin-left: 400px;
        .middle-card{
            margin-left: 10px;
            width: 95%;
        }
        .work-card{
            padding: 10px;
        }
        .classify{
            position: relative;
            :deep(.el-menu--horizontal){
                width: 450px;
                display: flex;
                justify-content: space-around;
            }
            .publish{
                z-index: 100;
                position: absolute;
                right: 100px;
                top: 30px;
            }
        }
    }

    .right-card{
        margin-left: 10px;
        width: 360px; 
        h2{
            margin-bottom: 30px;
        }
        .el-timeline{
            font-size: 20px;
        }
    }

}

</style>
