import { defineStore } from "pinia";
export const useStore = defineStore('myInfo',{
    persist: true,
    state:()=>{
        return{
            introInfo :{
                avatar:'',
                id:"",
                nickname:'',
                age:'',
                gender:'',
                bg:'',
                intro:'',
                focusCount:'',
                fansCount:'',
              },
            chatUserList:[
                {id:'000000',nickname:'AI',avatar:'http://192.168.238.134:9000/mychat-miniprogram/user/avatar/000000.png',
            chatRecord:[{
                sendId:'000000',
                recId:'100000',
                type:0,
                content:'你好啊你好！',
                status:false,
                time:"2024-12-08 15:50:03.0"
            }]}
            ]
        }
    }
})