<template>
  
</template>

<script setup>
import {ref,onMounted} from 'vue'
import {useStore} from '@/pinia/index'
const store = useStore()

onMounted(() => {

})
</script>

<style>

</style>