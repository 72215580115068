
/**
 * 根据传入的时间与现在的时间差
 * 将时间差转成可读字符串 如:刚刚、3秒前、一小时前等等
 *
 * @param time 需要进行比较的毫秒级时间(毫秒 同时需要 小于 当前时间)(这里通过LTD转为时间戳)
 * @return 返回可读字符串, 大于20天的 将会直接显示日期
 */
export function timeDifference(time) {
    if (time === null || time === undefined) {
      return ''
    }
   let date = new Date(time)
   let timestamp = date.getTime();
    // 获取两个日期之间相差的毫秒数
    const timeDifference = Math.abs(Date.now() - timestamp)

    const days = timeDifference / 1000 / 60 / 60 / 24
    const hours = timeDifference / 1000 / 60 / 60 - (24 * Math.floor(days))
    const minutes = timeDifference / 1000 / 60 - (24 * 60 * Math.floor(days)) - (60 * Math.floor(hours))
    const seconds = timeDifference / 1000 - (24 * 60 * 60 * Math.floor(days)) - (60 * 60 * Math.floor(hours)) - (60 * Math.floor(minutes))
   
    // 1分钟以内(1分钟 = 60000ms)
    if (timeDifference < 60000) {
      // 如果是5秒内
      const just = 5
      if (timeDifference < 1000 * just) {
        return '刚刚'
      } else {
        return Math.floor(seconds) + '秒前'
      }
    }
   
    // 一小时内(大于 1分钟[60000ms] 小于 1小时[3600000ms])
    if (timeDifference >= 60000 && timeDifference < 3600000) {
      return Math.floor(minutes) + '分钟前'
    }
   
    // 一天内
    if (timeDifference >= 3600000 && timeDifference < 86400000) {
      return Math.floor(hours) + '小时前'
    }
   
    // 二十天内
    const thirtyDays = 86400000 * 30
    if (timeDifference >= 86400000 && timeDifference < thirtyDays) {
      return Math.floor(days) + '天前'
    }
   
    return '很久之前'
  }

  export function splitData(data){
    if(data==''||data==null){
      return []
    }else{
      return data.split(',')
    }
  }
export function getCurrentTimeFormatted() {
  // 创建一个新的Date对象，表示当前时间
  const now = new Date();

  // 提取年、月、日、小时、分钟、秒和毫秒
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并确保是两位数
  const day = String(now.getDate()).padStart(2, '0'); // 确保日期是两位数
  const hours = String(now.getHours()).padStart(2, '0'); // 确保小时是两位数
  const minutes = String(now.getMinutes()).padStart(2, '0'); // 确保分钟是两位数
  const seconds = String(now.getSeconds()).padStart(2, '0'); // 确保秒是两位数
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0'); // 确保毫秒是三位数

  // 拼接成所需的格式
  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  return formattedTime;
}
