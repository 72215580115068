<template>
    <el-card>
    <el-popover popper-class="el-popover-self" placement="right"  trigger="hover" :show-arrow="false" :width="350" show-after="100">
      <template #reference>
        <div class="avatar"><el-avatar :size="50" :src="blogInfoItem.avatar" /></div>
      </template>
      <div class="blogPop">
            <div class="topArea">
                <el-button class="popOtherBtn">···</el-button>
                <img :src="blogInfoItem.bg" alt="TA的背景图片">
                <div class="popAvator">
                    <img :src="blogInfoItem.avatar" alt="TA的头像">
                </div>
                <span class="popName">{{blogInfoItem.nickname}}</span>
            </div>
            <div class="bottomArea">
                <div class="popFocusAndFan">
                    <span>关注&nbsp;{{blogInfoItem.focusCount}}</span>
                    <span>|</span>
                    <span>粉丝&nbsp;{{blogInfoItem.fansCount}}</span>
                </div>
                <div class="popBtn" v-if="blogInfoItem.onFocus!=0">
                    <el-button round>+关注</el-button>
                    <el-button round @click="goSixin(blogInfoItem)">私信</el-button>
                </div>
                <div class="popBtn" v-else>
                    <el-button round>查看主页</el-button>
                </div>
            </div>
      </div>
    </el-popover>
        <div class="info"><el-text class="name" tag="b">{{blogInfoItem.nickname}}</el-text>
        <el-check-tag   
            effect="light"
            round
            :checked="!blogInfoItem.onFocus==1"
            @change="gotoFocus"
            v-if="blogInfoItem.onFocus!=0"
            type="warning"
            >
            {{blogInfoItem.onFocus==1?'√已关注':'+关注'}}
        </el-check-tag>
        <br/>
        <el-text>{{blogInfoItem.publishTime}}</el-text></div>
        
        <el-dropdown v-if="blogInfoItem.userId!=store.introInfo.id" class="attention" trigger="click">
            <span class="el-dropdown-link">
            <div class="otherIcon">
            <el-icon class="el-icon--middle"><ArrowDown/></el-icon>
            </div>
            </span>
            <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="gotoFocusUser">关注TA</el-dropdown-item>
                <el-dropdown-item>拉黑TA</el-dropdown-item>
            </el-dropdown-menu>
            </template>
        </el-dropdown>

        <div class="content">
            <!-- 标题 -->
            <div>
                <el-text class="content-title" size="large" tag="b">{{blogInfoItem.title}}</el-text>
                <el-tag type="primary" v-if="blogInfoItem.tags[0]">{{blogInfoItem.tags[0]}}</el-tag>
                <el-tag type="success" v-if="blogInfoItem.tags[1]">{{blogInfoItem.tags[1]}}</el-tag>
                <el-tag type="info" v-if="blogInfoItem.tags[2]">{{blogInfoItem.tags[2]}}</el-tag>
            </div>

            <!-- 内容 -->
            <div class="main-content">
                <el-text size="large" id="blogContent">
                    
                </el-text><br/>
                  <div class="main-imgs" v-if="blogInfoItem.media!=''">
                    <el-image
                        v-for="(item,index) in blogInfoItem.media" :key="index"
                        style="width: 130px; height: 130px"
                        :src="item"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        :preview-src-list="blogInfoItem.media"
                        fit="cover"
                        :initial-index="0"
                    />
                </div>
            </div>
            <div>
                <el-tag type="info" class="iptag">ip:{{blogInfoItem.publishIp}}</el-tag>
            </div>
            <!-- 互动 -->
            <div class="interaction">
                <div @click="islike = !islike"><i :class="blogInfoItem.onLike?'iconfont icon-aixin1':'iconfont icon-aixin'" ></i><span>{{blogInfoItem.likeCount}}</span></div>
                <div @click="ElMessage.error('功能开发中')"><i class="iconfont icon-pinglun"></i><span>{{blogInfoItem.commentCount}}</span></div>
                <div @click="ElMessage.error('功能开发中')"><i class="iconfont icon-fenxiang"></i><span>{{blogInfoItem.repostCount}}</span></div>
            </div>

            <!-- 评论区 -->
            <div class="discuss">
                <div class="godis"><el-input
                style="width: 550px"
                placeholder="留下属于你的评论吧~"
                prefix-icon="EditPen"
                />
                <el-icon><Promotion /></el-icon>
                </div>


                <div class="discontent"></div>
            </div>
        </div>
        
    </el-card>
</template>

<script setup>
import {ref, defineProps, getCurrentInstance,onMounted} from "vue"
import {useRouter} from 'vue-router'
import {useStore} from '@/pinia/index.js'
import {ElMessage} from 'element-plus'
import axios from 'axios'
const router = useRouter()
const {proxy} = getCurrentInstance()
const store = useStore()
const {blogInfoItem} = defineProps(["blogInfoItem"])
let islike = ref(false)
onMounted( async() => {
    const {data} = await axios.get(blogInfoItem.content)
    blogContent.innerHTML = data
})
const gotoFocus = async()=>{
    if(!store.introInfo.id){
        return ElMessage.error('请先登录')
    }
    // 发送请求
    const res = await proxy.$api.focusUserById({userId:store.introInfo.id,focusUserId:blogInfoItem.value.userId,isFocus:blogInfoItem.value.isFocus})
    ElMessage.success(res)
    if(blogInfoItem.value.isFocus){ //为真取关
        let newFocusCount = parseInt(store.introInfo.focusCount,10)
        store.introInfo.focusCount = newFocusCount-1
    }else{
        let newFocusCount = parseInt(store.introInfo.focusCount,10)
        store.introInfo.focusCount = newFocusCount+1
    }
    blogInfoItem.value.isFocus = !blogInfoItem.value.isFocus
    store.isLoginAndRequest = false
}
const goSixin = (item)=>{
    console.log(item.userId);
    console.log(item);
    let index = -1
    const res = store.chatUserList.find(a=>{    //找到了
        index++
        if(a.id == item.userId){
            let tmpobj = {}
            tmpobj = store.chatUserList[0]
            store.chatUserList[0] = store.chatUserList[index]
            store.chatUserList[index] = tmpobj
            return true
        }
    })
    if(res == undefined){   //没找到
        store.chatUserList.unshift({
            id:item.userId,
            avatar:item.avatar,
            age:item.age,
            bg:item.bg,
            fansCount:item.fansCount,
            focusCount:item.focusCount,
            gender:item.gender,
            intro:item.intro,
            nickname:item.nickname,
            chatRecord:[]
        })
    }
    router.push('/chat')
}
</script>
<style lang="scss">
.el-popover-self{
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>

.blogPop{
    padding: 0;
    height: 200px;
    .topArea{
        position: relative;
        width: 100%;
        height: 55%;
        img{
            width: 100%;
            height: 100%;
        }
        .popAvator{
            position: absolute;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            top: 5%;
            left: 50%;
            transform: translate(-50%,0%);
            img{
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }

        }
        .popName{
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
            color: black;
            font-weight: 700;
        }
        .popOtherBtn{
            width: 35px;
            height: 35px;
            position: absolute;
            top: 5%;
            right: 5%;
            border-radius: 50%;
            font-weight: 600;
        }
    }
    .bottomArea{
        width: 100%;
        height: 45%;
        .popFocusAndFan{
            display: flex;
            justify-content: space-around;
            line-height: 30px;
            width: 210px;
            height: 30px;
            margin: 5px auto;
            color: black;
        }
        .popBtn{
            width: 60%;
            display: flex;
            justify-content: space-around;
            margin: 0 auto;
        }
    }
}
.avatar{
    float: left;
    vertical-align:middle
}
.info{
    display: inline-block;
    margin-left: 20px;
    .name{
        font-size: 18px;
        color: black;
    }
    .el-check-tag {
    border-radius: 15px;
    margin-left: 15px;
    font-size: 12px;
    }   
}
.attention{
    float: right;
    .otherIcon{
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    .el-icon{
      color: black;
      width: 40px;
      height: 40px;
    }
  }
  .otherIcon:hover{
    background-color: rgb(227, 220, 220);
  }
}
.content{
    padding: 20px 70px;
    .content-title{
        font-size: 20px;
        color: black;
    }
    .el-tag{
        margin-left: 10px;
    }
    .main-content{
        margin-top: 20px;
        color: rgb(51, 51, 51);
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
        .main-imgs{
            display: grid;
            grid-template-columns: repeat(4,1fr);
            .el-image{
                margin: 0 5px 10px 0;
            }
        }
    }
    .iptag{
        width: 100px;
        height: 30px;
        margin-left: -2px;
        font-size: 20px;
    }
    .interaction{
        width: 300px;
        display: flex;
        justify-content: space-around;
        float: right;
        margin-top: 20px;
        i{
            font-size: 20px;
            margin-right: 5px;
            vertical-align: middle;
            color: red;
        }
        div:hover{
            color: red;
            cursor: pointer;
        }
    }
    .discuss{
        .godis{
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 70px;
            height: 45px;
            background-color: rgb(240, 240, 240);
            :deep(.el-input__wrapper){
            border-radius: 30px;
        }
        }

    }
}
</style>