import service from "./request";

export default{
    ApigetCaptchaInfo(){    //获取base64和随机数
        return service.get('/webuser/login/captcha')
    },
    ApiGotoLogin(logininfo){    //用户登录
        return service({
            method:'POST',
            url:'/webuser/login',
            data:logininfo
        })
    },
    gotoRegistyApi(registyInfo){
        return service({
            method:'POST',
            url:'/webuser/registy',
            data:registyInfo
        })
    },
    ApiGetUserInfo(id){      //加载个人卡片
        return service({
            method:'GET',
            url:`/webuser/user/${id}`,
        })
    },
    ApiGetBlogInfo({userId,type,pageNum,pageSize}){     //加载主页blog
        return service({
            method:'POST',
            url:'/webblog/blog',
            data:{
                userId,
                type,
                pageNum,
                pageSize
            }
        })
    },
    ApiupdateUserInfo(userInfo){
        return service({
            method:'POST',
            url:'/webuser/updateInfo',
            data:{
                ...userInfo
            }
        })
    },
    ApiGetNotice(){
        return service.get('/webuser/notice')
    },
    focusUserById(ids){
        return service({
            method:'GET',
            url:'app/user/focus',
            params:{
                userId:ids.userId,
                focusUserId:ids.focusUserId,
                isFocus:ids.isFocus
            }
        })
    },
    testApi(){
        return service.get('/test')
    }
}