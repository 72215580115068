<template>
    <div class="chatItem">
        <div class="chatAvatar" :class="chatRecord.sendId==store.introInfo.id?'me':'other'">
            <img :src="chatRecord.sendId==store.introInfo.id?store.introInfo.avatar:chatAvatar" alt="">
        </div>
        <div class="chatFrame" :class="chatRecord.sendId==store.introInfo.id?'me':'other'">{{chatRecord.content}}</div>
    </div>
</template>
<script setup>
import { ref, onMounted, defineProps } from "vue";
import {useStore} from '@/pinia/index.js'
const {chatRecord,chatAvatar} = defineProps(['chatRecord','chatAvatar'])
const store = useStore()
onMounted(() => {
    // console.log(store.chatUserList[parseInt(store.currentChatUser.index)]);
})
</script>
<style lang="scss" scoped>
    .me{
        float: right;
        margin-left: 10px;
        background-color: greenyellow;
    }
    .other{
        float: left;
        margin-right: 10px;
        background-color: white;
    }
    .chatItem{
        display: block;
        width: 100%;
        min-height: 70px;
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: 15px;
        .chatAvatar,.chatAvatar img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
        .chatFrame{
            margin-top: 10px;
            font-size: 28px;
            padding: 12px;
            max-width: calc(100% - 140px);
            word-break: break-all;
            white-space: pre-wrap;
        }
    }
    
</style>
