<template>
  <router-view :ws="ws"></router-view>
</template>
<script setup>
import {ref,onMounted,getCurrentInstance} from 'vue'
import {useStore} from '@/pinia/index'
const {proxy} = getCurrentInstance()
const store = useStore()
const token = sessionStorage.getItem("LOGIN_TOKEN")
const ws = ref(null)
onMounted(() => {
  if(token!=null){
    ws.value = new WebSocket(`ws://39.103.58.175:8002/mychat/${store.introInfo.id}`)
        ws.value.onopen = (e)=>{
        console.log("连接成功");
        }
        ws.value.onmessage =  (e)=>{
          const data = JSON.parse(e.data)
          let index = -1
          let res = store.chatUserList.find(item=>{
            index++
            if(item.id == data.userInfoDto.id){
              item.chatRecord.push(data.userMsg)
              let tmpobj = {}
              tmpobj = store.chatUserList[0]
              store.chatUserList[0] = store.chatUserList[index]
              store.chatUserList[index] = tmpobj
              return true
            }
          })
          if(res == undefined){
            data.userInfoDto.chatRecord = []
            data.userInfoDto.chatRecord.push(data.userMsg)
            store.chatUserList.unshift(data.userInfoDto)
          }
        }
        ws.value.onclose = (e)=> {
        console.log("关闭客户端连接");
        };

        // 连接失败后的回调函数
        ws.value.onerror = (e)=> {
        console.log("连接失败了");
        };
        window.onbeforeunload = ()=> {
            ws.value.close();
        } 
  
}})
async function getUserInfo(id,data){
  const userInfo = await proxy.$api.ApiGetUserInfo(id)
      userInfo.chatRecord = []
      userInfo.chatRecord.push(data)
      store.chatUserList.unshift(userInfo)
      console.log(store.chatUserList.length);
}
</script>



<style lang="scss">
body{
    background-color:rgb(246, 246, 246)
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  li{
    list-style-type: none;
  }
}
</style>
