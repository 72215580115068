<template>
  <div>
    <el-card  shadow="hover" class="left-card">
      <div class="avator"><img :src="!introInfo.avatar?require('@/assets/avatar.webp'):introInfo.avatar"></div>
      <div class="name"><span>{{introInfo.nickname}}</span></div>
      <div class="intro">
        {{introInfo.intro}}
      </div>
      <slot name="btn"></slot>
      <!-- <div class="interact">
        <div><span class="interact-name">关注</span><span class="iconfont icon-wodeguanzhu"></span><span class="interact-num">{{introInfo.attention}}</span></div>
        <div><span class="interact-name">粉丝</span><span class="iconfont icon-fensipa"></span><span class="interact-num">{{introInfo.fans}}</span></div>
        <div><span class="interact-name">获赞</span><span class="iconfont icon-icon_huozan"></span><span class="interact-num">{{introInfo.liked}}</span></div>
      </div> -->
      <slot name="interact"></slot>
    </el-card>
  </div>
</template>

<script setup>
import { ref,defineProps, onMounted } from "vue";
import {useRoute} from 'vue-router'
import {useStore} from '@/pinia/index'
const route = useRoute()
const {introInfo} = defineProps(['introInfo'])
onMounted(() => {

})
const store = useStore()

</script>

<style lang="scss" scoped>
  .left-card{
    border-radius: 20px;
    text-align: center;
    position: relative;
    width: 350px;
    height: 480px;
    overflow: visible;
    .avator{
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      left: 50%;
      transform: translate(-50%,-60%);
      background-color: white;
      border: 2px solid purple;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .name{
      margin: 70px 0 20px 0;
      font-size: 30px;
      font-weight: 600;
    }
    .intro{
      text-indent: 2em;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      width: 100%;
      height: 120px;
    }
    .goPagebtn{
      margin: 20px 0;
      width: 200px;
      border-radius: 17px;
      border: 3px solid rgb(154, 204, 251);
    }
    .interact{
      align-content: space-around;
      display: flex;
      justify-content: space-around;
      .iconfont{
        font-size: 30px;
      }
      .interact-name{
        font-size: 20px;
      }
      .interact-num{
        color: red;
        font-size: 20px;
        font-weight: 600;
      }
      span{
        margin: 10px 0;
        display: block;
      }
    }
  }
</style>