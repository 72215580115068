import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import test from '@/views/test.vue'
import Chat from '@/views/Chat.vue'
import PersonPg from '@/views/PersonPg.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/registy',
    name: 'registy',
    component: Login
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },
  {
    path:'/personpg/:id',
    name:'personpg',
    component:PersonPg,
    children:[
      {
        path:'',
        name:'personHome',
        component:()=> import('@/views/personpg/PersonHome.vue')
      },      
      {
        path:'blog',
        name:'personBlog',
        component:()=> import('@/views/personpg/PersonBlog.vue')
      },
      {
        path:'like',
        name:'personLike',
        component:()=> import('@/views/personpg/PersonLike.vue')
      },
      {
        path:'focus',
        name:'personFocus',
        component:()=> import('@/views/personpg/PersonFocus.vue')
      },
      {
        path:'fans',
        name:'personFans',
        component:()=> import('@/views/personpg/PersonFans.vue')
      }
    ]
  },
  {
    path:'/chat',
    name:'chat',
    component:Chat,
    children:[
      {
        path:'',
        name:'chatHome',
        component:()=> import('@/views/chat/ChatHome.vue')
      },
      {
        path:'friend',
        name:'chatFriend',
        component:() => import('@/views/chat/ChatFriend.vue')
      },
      {
        path:'search',
        name:'chatSearch',
        component:()=> import('@/views/chat/ChatSearch.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// router.beforeEach((to,from,next)=>{
//   if(to.path == '/login' || '/' || '/test'){
//     return next()
//   }else{
//     const token = sessionStorage.getItem("LOGIN_TOKEN")
//     if(token == "" || token == null){
//       ElMessage.error("请先登录")
//     }else{
//       return next()
//     }
//   }
// })
export default router
