import axios from 'axios'
import { ElMessage } from 'element-plus'
const service = axios.create({
    // baseURL: 'http://localhost:10010',
    baseURL: 'http://39.103.58.175:10010/',

    timeout: 8000,
})


service.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("LOGIN_TOKEN")
    if(config.url.includes('login')||config.url.includes('registy')){
        return config
    }
    if(token==null){
        return ElMessage.error("您尚未登录!")
    }
    config.headers.set("token",token)
    return config

  }, function (error) {

    return Promise.reject(error);
  });


service.interceptors.response.use((res)=>{
    const {code,data} = res.data
    if(code == 200){
        return Promise.resolve(data)
    }else{
        if(code== 201||code ==202){
            sessionStorage.removeItem("LOGIN_TOKEN")
        }
        const NETWORK_ERROR = "网络异常"
        ElMessage.error(data || NETWORK_ERROR)
        return Promise.reject(data)
    }
})




export default service