<template>
    <Header/>
    <div class="personpgcontainer">
        <div class="personpg-left-card">
            <h1>个人主页</h1>
            <el-menu
                :default-active="route.path"
                router
            >
            <el-menu-item :index="'/personpg/'+pathId" >
                <span class="iconfont icon-wodezhuye"></span>  
                <span>我的主页</span>
            </el-menu-item>
            <el-menu-item :index="'/personpg/'+pathId+'/blog'">
                <span class="iconfont icon-boke"></span>   
                <span>我的博客</span>
            </el-menu-item>
            <el-menu-item :index="'/personpg/'+pathId+'/like'">
                <span class="iconfont icon-dianzan"></span>    
                <span>我的点赞</span>
            </el-menu-item>
            <el-menu-item :index="'/personpg/'+pathId+'/focus'">
                <span class="iconfont icon-wodeguanzhu"></span>
                <span>我的关注</span>
            </el-menu-item>
            <el-menu-item :index="'/personpg/'+pathId+'/fans'">
                <span class="iconfont icon-fensipa"></span>
                <span>我的粉丝</span>
            </el-menu-item>
            </el-menu>
        </div>
        <div class="personpg-main-card">
            <router-view v-slot="{ Component }">
                <KeepAlive :include="['PersonHome']">
                    <component :is="Component" />
                </KeepAlive>
            </router-view>
            </div>
    </div>
</template>

<script setup>
import Header from "../components/Header"
import {ref, onBeforeMount} from 'vue'
import {useRoute} from 'vue-router'
const route = useRoute()
const pathId = ref(null)
onBeforeMount(() => {
    pathId.value = route.params.id
    console.log(route);
})
</script>

<style lang="scss" scoped>
.personpgcontainer{
    position: fixed;
    top: 85px;
    width: 100%;
    height: calc(100% - 85px);
    .personpg-left-card{
        .el-menu{
            border-right: 0;
            .iconfont{
                margin-left: -10px;
                margin-right: 10px;
            }
            :deep(span){
                font-size: 20px;
            }
        }
        padding: 0 10px;
        h1{
            font-size: 28px;
            margin-bottom: 15px;
            margin-left: 10px;
        }
        padding-top: 30px;
        border-radius: 10px;
        position: absolute;
        width: 12%;
        height: 800px;
        left: 12%;
        background-color: white;
    }
    .personpg-main-card{
        width: 45%;
        margin-left: 25%;
        min-height: 100%;
        border-radius: 0 40px 0 40px;
        background-color: white;
        overflow: hidden;
    }
}
</style>